import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryFnMe, queryKeyMe } from '../hook/use-me';
import { queryFnTheme, queryKeyTheme } from '../hook/theme';

export function mainLoader(queryClient: QueryClient) {
  return async function ({ request }: LoaderFunctionArgs) {
    const embed = new URL(request.url).searchParams.get('embed');

    try {
      await queryClient.fetchQuery(queryKeyTheme, queryFnTheme);
    } catch (e) {
      return redirect(`/error${embed ? `?embed=${embed}` : ''}`);
    }

    try {
      await queryClient.fetchQuery(queryKeyMe, queryFnMe);
    } catch (e) {
      localStorage.clear();
      return redirect(`/login${embed ? `?embed=${embed}` : ''}`);
    }

    return null;
  };
}
