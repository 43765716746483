import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Logo } from '../logo';

import style from './style.module.scss';
import { useAtom } from 'jotai/index';
import { embedAtom } from '../../pages/Bootstrap';

export function Footer() {
  const [embed] = useAtom(embedAtom);

  return (
    <footer className={`${style.footer} d-flex align-items-center mt-5`}>
      <Container>
        <Row className={'align-items-center'}>
          <Col xs={4}>{!embed && <Logo height={25} />}</Col>
        </Row>
      </Container>
    </footer>
  );
}
