import { Outlet, useSearchParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const embedAtom = atomWithStorage<string | null>('embed', localStorage.getItem('embed'));

export function Bootstrap() {
  const [searchParams] = useSearchParams();
  const embedParam = searchParams.get('embed');

  const [, setEmbed] = useAtom(embedAtom);

  console.log({ embedParam });

  if (embedParam) setEmbed(embedParam);

  return <Outlet />;
}
