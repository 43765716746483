import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { ROLES_API } from '../../@types';

export interface Theme {
  brand: {
    name: 'RightMove' | 'SLP' | 'The Agency';
    logo: {
      width: number;
      height: number;
    };
    /**
     * Default to logo.svg
     */
    headerLogo?: string;
  };
  requests: {
    intro?: string;
    description?: string;
    items: {
      id: number;
      tile: {
        title: string;
        icon: string;
        link: string;
        disabled: boolean;
        hide: boolean;
      };
      form: {
        description: string;
        data: any;
      };
    }[];
    techStack: {
      title: string;
      url: string | Record<string, string>;
      image: string;
      roles?: ROLES_API[];
      states?: string[];
    }[];
  };
  dashboard: {
    columns: Record<string, { label: string }>;
    tag: Record<
      string,
      {
        color: string;
      }
    >;
  };
}

export const queryFnTheme = (): Promise<Theme> => axios.get(`/${process.env.REACT_APP_BRAND_FOLDER}/content.json`, { baseURL: '' });
export const queryKeyTheme = ['.theme'];
export const useProjectTheme = () => useQuery(queryKeyTheme, queryFnTheme);

export const useCurrentPage = () => {
  const { state } = useLocation();
  const { data: theme } = useProjectTheme();

  return theme?.requests.items.find(item => item.tile.title === state.id);
};
